import 'react-datepicker/dist/react-datepicker.css'
import '../styles/globals.css'
import '../styles/slick.css'
import '../styles/animation.css'
import '../styles/calendar.css'
import '../styles/customSlider.css'
import { GlobalStyles } from 'twin.macro'
// @ts-expect-error TS2307
import { AppProps } from 'next/dist/next-server/lib/router/router'
import Footer from '../src/components/Footer/Footer'
import { TnCChangedModal } from '../src/components/TnCChangedModal/TnCChangedModal'
import { BUYER_ROUTE, GUEST_ROUTE, Route, SELLER_ROUTE } from 'src/consts/route'
import { Auth0Provider } from '@auth0/auth0-react'
import { UrqlWrapper } from 'src/components/UrqlWrapper'
import { AppGlobalStyle } from 'src/util/AppGlobalStyle'
import { GuestStyle } from 'styles/landing_renewal/GuestStyle'
import { CurrentUserProvider } from 'src/contexts/currentUser'
import { UiProvider } from 'src/contexts/ui'
import { useRouter } from 'next/router'
import { CartProvider } from 'src/contexts/cart'
import { useDeployManager } from 'src/hooks/useDeployManager'
import { CommonContextProvider } from 'src/contexts/commonContext'
import { MobileMenu } from 'src/components/MobileMenu/MobileMenu'
import { BrowsingHistoryProvider } from 'src/contexts/browsingHistory'
import { SellerHeader } from 'src/components/OverallUIComponents/SellerHeader/SellerHeader'
import { BuyerHeader } from 'src/components/OverallUIComponents/BuyerHeader/BuyerHeader'
import FooterNew from 'src/components/OverallUIComponents/FooterNew/FooterNew'
import { initHotjar } from 'src/util/hotjar'
import { useEffect } from 'react'
import { NextPageWithLayout } from 'next'
import { PreviousPageProvider } from 'src/contexts/previousPage'
import { SellerGuestStyle } from 'styles/seller/landing/SellerGuestStyle'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    initHotjar()
  }, [])

  const { pathname } = useRouter()
  const matchPath = (routePathes: string[], path: string) => routePathes.some((it) => it === path)

  const guestRoot = [Route.landing, Route.campaignLanding, Route.scta, Route.termsAndConitions] as string[]

  const guestSellerRoute = [Route.sellerLanding] as string[]

  const isGuestRoot = guestRoot.includes(pathname)
  const isGuestSellerRoot = guestSellerRoute.includes(pathname)

  const isProductList =
    pathname === Route.productSearchResultOld ||
    pathname === Route.specialFeatureProducts ||
    pathname === Route.specialFreighter ||
    pathname === Route.productTop ||
    pathname === Route.productSearchDeliveryResult ||
    pathname === Route.productSearchDelivery ||
    // @ts-expect-error TS2551
    pathname === Route.meatFeatureProducts ||
    pathname === Route.asparagusFeatureProducts ||
    pathname === Route.chickenFeatureProducts ||
    // @ts-expect-error TS2339
    pathname === Route.morningVegetablesProducts ||
    pathname === Route.mushroomProducts ||
    pathname === Route.favorite

  const isOverallUI =
    pathname === Route.productSearch || pathname === Route.productSearchResult || pathname === Route.coupon

  // @ts-expect-error TS7006
  const getLayout = Component.getLayout ?? ((page) => page)
  const Page = getLayout(<Component {...pageProps} />)

  // LPは他ページとデザインになるため共通のレイアウト・コンポーネントを適用しない
  if (isGuestRoot || isGuestSellerRoot) {
    return (
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!}
        redirectUri={typeof window !== 'undefined' ? `${window.location.origin}${Route.authLoading}` : undefined}
      >
        <UrqlWrapper>
          <CurrentUserProvider>
            {isGuestRoot && <GuestStyle />}
            {isGuestSellerRoot && <SellerGuestStyle />}
            <PreviousPageProvider>{Page}</PreviousPageProvider>
          </CurrentUserProvider>
        </UrqlWrapper>
      </Auth0Provider>
    )
  }

  return (
    <>
      <div tw="flex flex-col bg-white h-full">
        <GlobalStyles />
        <AppGlobalStyle />
        <Auth0Provider
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
          audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!}
          redirectUri={typeof window !== 'undefined' ? `${window.location.origin}${Route.authLoading}` : undefined}
        >
          <UrqlWrapper>
            <CurrentUserProvider>
              <CartProvider>
                <BrowsingHistoryProvider>
                  <UiProvider>
                    <CommonContextProvider>
                      <PreviousPageProvider>
                        {(matchPath(SELLER_ROUTE, pathname) ||
                          matchPath(GUEST_ROUTE, pathname) ||
                          pathname === Route.sellerOnboarding) && <SellerHeader />}
                        {(matchPath(BUYER_ROUTE, pathname) || pathname === Route.onboarding) && <BuyerHeader />}
                        {isProductList && (
                          <div tw="flex-1 bg-white w-full landing-pc:max-width[1200px] max-w-xl mx-auto">
                            <Component {...pageProps} />
                          </div>
                        )}
                        {!isProductList && !isOverallUI && (
                          <div tw="flex-1 bg-white w-full max-w-xl mx-auto">
                            <Component {...pageProps} />
                          </div>
                        )}
                        {isOverallUI && (
                          <div tw="flex-1 bg-white mx-auto w-full">
                            <Component {...pageProps} />
                          </div>
                        )}
                        {(matchPath(SELLER_ROUTE, pathname) ||
                          matchPath(GUEST_ROUTE, pathname) ||
                          pathname === Route.sellerOnboarding) && <Footer />}
                        {(matchPath(BUYER_ROUTE, pathname) || pathname === Route.onboarding) && <FooterNew />}
                        <MobileMenu />
                        <TnCChangedModal />
                        <DeployManager />
                      </PreviousPageProvider>
                    </CommonContextProvider>
                  </UiProvider>
                </BrowsingHistoryProvider>
              </CartProvider>
            </CurrentUserProvider>
          </UrqlWrapper>
        </Auth0Provider>
      </div>
      <div id="modal" tw="max-w-xl mx-auto relative" />
    </>
  )
}

function DeployManager() {
  useDeployManager()
  return null
}

export default MyApp
